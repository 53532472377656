.thanks {
  width: 100vw;
  height: 200vh;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%),
    url("../../assets/img/bg-double.png");
  background-size: cover;
  position: relative;

  .top {
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 1) 50%);

    .wrapper {
      padding: 10px 20px;
      padding-bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;


      .logo {
        height: 60px;
      }
    }
  }

  .container {
    width: 60%;
    height: auto;
    position: absolute;
    top: 20vh;
    left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 5px;

    h1 {
      font-size: 50px;
    }

    .aifilms {
      font-size: 15px;
      font-style: italic;
    }

    h2 {
      margin: 20px;
    }

    p {
      font-size: 20px;
    }

    .img-robot {
      width: 50%;
      height: auto;
      margin-bottom: 25px;
    }

    .remember {
      width: 50%;
    }
  }
}

@media screen and (max-width: 480px) {
  .thanks {
    height: 150vh;

    .container {
      width: 75%;
      left: 7.5%;
      align-items: inherit;

      h2 {
        margin: 20px 0px;
      }

      .img-robot {
        width: 100%;
        height: auto;
        margin-bottom: 25px;
      }

      .remember {
        width: 100%;
      }
    }
  }
}