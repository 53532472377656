.terms {
  color: "black";
  background-color: "white";
  max-height: "200px";
  overflow: "scroll";
  padding-bottom: 15px;
}

.countrySelect {
  margin-bottom: 10px;
}

.submission {
  width: 100vw;
  height: 200vh;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%),
    url("../../assets/img/bg-double.png");
  background-size: cover;
  position: relative;

  .top {
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 1) 50%);

    .wrapper {
      padding: 10px 20px;
      padding-bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 60px;
      }
    }
  }

  .container {
    width: 60%;
    height: auto;
    position: absolute;
    top: 20vh;
    left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 5px;

    h1 {
      font-size: 80px;
    }

    .aifilms {
      font-size: 15px;
      font-style: italic;
    }

    h2 {
      margin: 20px;
    }

    p {
      font-size: 20px;
    }

    .input {
      width: 70%;
      background-color: rgb(0, 0, 0);
      display: flex;
      margin-top: 20px;
      height: auto;
      border-radius: 5px;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      color: white;

      input {
        margin: 10px 0;
        flex: 9;
        height: 100%;
        border: none;
        padding: 20px 10px;
        border-radius: 5px;
      }

      label {
        margin: 10px;
        background: #000;
        position: relative;

        span {
          position: absolute;
          right: 0px;
          font-size: 10px;
          bottom: 0px;
        }
      }

      .error {
        margin: 10px 0;
        background: rgb(229, 96, 96);
        color: rgb(145, 0, 0);
        position: relative;
        font-size: 10px;
        padding: 5px;
        width: 50%;
        border: #fff 0.5px solid;
        border-radius: 5px;
        ;
      }

      .submissionButton {
        flex: 3;
        height: 100%;
        background-color: red;
        border: none;
        color: white;
        font-size: 22px;
        border-radius: 5px;
        padding: 10px;
        margin: 20px 0;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .submission {

    height: 200vh;

    .container {
      width: 75%;
      left: 7.5%;
      align-items: inherit;

      h1 {
        font-size: 50px;
      }

      h2 {
        margin: 20px 0px;
      }

      .input {
        width: 95%;

        label {
          margin: 0px;
        }

        input {
          padding: 20px 10px;
          margin: 10px 0;
        }
      }
    }
  }
}